export const whitelist = [
    ""
]

export const ogslist =  [
    "0xE19cA478A0aA0670Ee4C80d856ac10ADF8CAF449",
    "0xE8E58A7459733A5925F1F347Aa0a36E23191A2b1",
    "0xD67873FFFB3557626cB7289B4360968456A16620",
    "0x118B45C38CeDD1a2EcD369c6999a1C6dC961A5D5",
    "0xA69f1372F0a36dAAF02793b2f64dE5439722b320",
    "0xF369f0EDdCE98fCcE125897C118490b111c4fEF6",
    "0x1C622ABc83Aa62e628D29e7B8E3284dEabe03DBA",
    "0xEAD68159A42F6ad0abB65020C48893375092B94E",
    "0xBfac58a9098E7eF9D1a3f4aa966f9051dc319cd7",
    "0x5C7E0ad50E3D43a4C6d13038616339d44C26Eb8a",
    "0x1Dc92698FF50A1D6c098e3647e903C841AF6961C",
    "0xcD107Eca2e99aaC18A6BcC830c07c703dc5044D4",
    "0x2dB5Bd00047591dCe8BD8Ae765029A7AE3fa674F",
    "0x8078Dbe677642f385D865F669CAf2cd4aC06A771",
    "0x0F6E1af8191F2373dB37676F18a9e9A23A71EC67",
    "0x235Db30790260cA76C4Dea1BBAE1a9b770F66A9F",
    "0x79c9851146b68BbA10085a4DB246abE1002E6f00",
    "0xa5A88127cF59527850021fa6f4cbFc7149aad5Cb",
    "0x97219Ef57A528c1f28A07A8c028b13C25464AEA9",
    "0x3Da61E0091023AEF38a2232BaeF1DCC1074d0D52",
    "0x067002400659eb70835128868849c06D5f141189",
    "0x1Def9bebE8cEf5f1C0F5fC71F1f0c1312A273e15",
    "0x76Fad2dC7bDDbF7aa660CD2D6bc16911Be9B7b6E",
    "0x5e7A17f3f92A3fD0a79697876049410bEF882645",
    "0x9ad82Ab1c9Ba94F40F2DB2Ce04f48435F9dE0e04",
    "0xEC44F39Ad8862bDBED64Cc95372d208A1Db385d3",
    "0x05909d643d7902f858106a01aD80A63150DdD8Ea",
    "0xBfF7ED7341a3f47427913e0e73219dBBA58E2DA1",
    "0x8AAaf5aAD1f80c654A01ccCeCc454460601a427a",
    "0x3e844e96F09dCb25BA606e7CA4d34F5c8d00B7c0",
    "0xdCDd087E15d92c39c12D1716655f23066E604DBb",
    "0x8041e99F557F07F7bC9D26eA6895Aa004b68b01d",
    "0x20Ad150529C5b6fA563188D72Ca33C265B39D4bA",
    "0x2223d4b381cB62d2064cbE87b6CA13D98EfA2fe1",
    "0xb177695B0112319fAC0359e09357D8212e317CDf",
    "0x1B25144b290e05E5E3A4Cc381564f92f686c23F4",
    "0x8b4873246b98613545B1Ee3C682080994521258c",
    "0x20934C297Efa6931B435A184BF51394630CE55dd",
    "0xBe1f29ce958dC8eb97076956DEEB8d421b97Bd82",
    "0xbd688D5a40F09aDE3468D5B643B527847A0fFA44",
    "0x0d5673239ea18A39dAE03d6CeB5b0d85CdE0900F",
    "0x21B27F87595D4ec06658b445654256CB74C9b2E0",
    "0x0F5d3d3385BcB455C14665a8b38A85a1717177A1",
    "0x084a3dBc78159D8070892e7e99929C29b846E59A",
    "0xe6d272EBa89bCc86A0C7a32aa85A2A9F2cb2e1ab",
    "0xeA8dbe61d1b98cfd4E34Fc422CDcb95971aAC0F9",
    "0xccc8F80f97e06e08C348bAa92DC8BE13064E4b39",
    "0x6B7BF7afaC2432f521D6c6Aa2392466165798ae1",
    "0x3F32613Fd7f10F47469e344dE162d72e619fD94E",
    "0x22C52D3b0396305D29A937234182efFbcB245695",
    "0x58433Cc9472FF886b0beB213Ceba9F2c26e25468",
    "0x5E8bb171EfB4EF1DA79479C794d5B8ec550233f1",
    "0x4948491b93c244e126AB7F24471d0DDB16c30976",
    "0x541e2A5A2edB8649Da6865B3822f7d104d5E0280",
    "0x31c9eb2330130DfD09cf6D0512D4AC331DEb2Cc3",
    "0x3979e64a24a6368c70a075339f089661516b017e",
    "0x33665a42855c6770920934A89D615EA21CBf76A6",
    "0xE0367862C126b6bd0ae27886649D260E7989fdC8",
    "0x61B12A415D76C38F6c249E54c04AF361D520798F",
    "0xc95f2055e7e0e519aD1E70696FC30Da0E2Ae1109",
    "0x72194DAc7BeB999d01bD6b152f6787101E7a0B2E",
    "0x84Ea0F7B7D838722dC98e42ad12F1070b3404673",
    "0xdf5fee8ca398deeff5d81ec6d69b4329e840e7cb",
    "0xD809f431058826999B2b8A30eF328ADB3B172c3f",
    "0x16A504f8080ec4F42f324043410B371927d76E9A",
    "0x950069E2558338969779b72414F9553b1D057984",
    "0xe287ec58f637e457c3158c7c2eD0046EfC860180",
    "0xC907b60FD4625374bEb58A9F8beE146C75C11EeE",
    "0xDaE0bf5a5fc5B902d10e1CFE9f144Ee35B7e5edc",
    "0x66612BcCf1E6b9A484100D9c5f13ca5919A25E6b",
    "0xEa7D04268557F76AA0f3A8B406CA76bB5EB95553"
]
