// import logo from './logo.svg';
import './App.css';
import React from 'react';


require('dotenv').config()

function PhaseBar(props) {  
  const { state } = props.data;
  const OG = process.env.REACT_APP_OGS!="NONE"?true:null;
  
  function id(name) {
    var active = false;
    if(state==0 && name=="Pre")active=true;
    if(state==1 && OG && name=="OGs")active=true;
    if(state==1 && !OG && name=="WL")active=true;
    if(state==2 && name=="Public")active=true;
    return active?"active":"grayed";

  }
  return (
    <>
      <div className="phasebar">
        <span title={"PHASE 0 : Contract is live!"} id={id("Pre")}>Pre-Mint</span>
        <span title={"PHASE 1 : OGs can mint their tokens!"} id={id("OGs")}>OGs</span>
        <span title={"PHASE 2 : Whitelist sale!"} id={id("WL")}>WL</span>
        <span title={"PHASE 3 : Public sale is live! LFG! 🚀"} id={id("Public")}>Public</span>
      </div>
    </>

  );
}

export default PhaseBar;
