import './App.css';

const mapping = {
}

function Token(props) {
    let {tokenId,sampleId,minted} = props; //TODO lookup metadata from url
    const url = `${process.env.REACT_APP_ASSETS_URL}/${tokenId}.png`;


  return (
    <div className="Token" id={minted?"minted":"regular"} >
      {url?<>
        {/* <iframe title={`Token #${tokenId}`} src={uri}></iframe> */}
        <img alt={`Token #${tokenId}`} src={url}></img>
        <div className="Overlay"></div>
        </>
      :<></>}
    </div>
  );
}

export default Token;
